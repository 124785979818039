<template>
  <div class="sec-2textimg">
    <div class="sec-2textimg-text col-md-8">
      <h2 class="title">Sed illo dignissimos ea facilis error ab aliquid dolores</h2>
      <p class="paragraph">
        Lorem ipsum dolor sit amet. Non magnam asperiores in libero sint ut dolores voluptatum
        et maiores velit vel quam velit ut consequatur fugit. Aut recusandae necessitatibus aut
        repellat perferendis in autem quam ea tenetur
        culpa non quod voluptates</p>
    </div>
    <div class="sec-2textimg-img col-md-4">
      <v-img src="@/assets/images/pages/person-client.png" class="img"></v-img>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiOpenInNew } from '@mdi/js'

export default {

  setup() {
    return {
      icons: {
        mdiOpenInNew
      },
    }
  },
}
</script>

<style lang="scss">
/* Section avec image */
.sec-2textimg {
  width: 100;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  //padding: 1.2em 0px;

  .sec-2textimg-text {
    .title {
      font-weight: bold;
    }

    .paragraph {
      text-align: justify;
    }
  }

  .sec-2textimg-img {
    .img {
      height: 14em;
      border-radius: 1em !important;
    }
  }
}
</style>