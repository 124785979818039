<template>
  <div>
    <!-- Welcome main section -->
    <v-row>
      <div class="main-sec">
        <div class="btn-to-bottom"></div>
        <div class="main-sec-text">
          <h1 class="main-sec-title">ABOUT US</h1>
          <!-- <h5 class="main-sec-subtitle">Here comes the business networking platform that you've been waiting for</h5>
            <v-btn class="main-sec-btn">Log in here</v-btn> -->
        </div>
      </div>
    </v-row>
    <v-row class="main-subsec">
      <v-col cols="11" md="4" class="main-subsec-item">
        <div class="main-sec-text">
          <h5 class="main-sec-subtitle">Here comes the networking platform</h5>
          <!-- <p class="main-sec-subtitle-sub">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
            doloremque laudantium, totam
            rem aperiam
          </p> -->
        </div>
      </v-col>
      <v-col cols="11" md="4" class="main-subsec-item">
        <div class="main-sec-text">
          <h5 class="main-sec-subtitle">Here comes the networking</h5>
          <!-- <p class="main-sec-subtitle-sub">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
            doloremque laudantium, totam
            rem aperiam
          </p> -->
        </div>
      </v-col>
      <v-col cols="11" md="4" class="main-subsec-item">
        <div class="main-sec-text">
          <h5 class="main-sec-subtitle">Here comes the networking</h5>
          <!-- <p class="main-sec-subtitle-sub">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
            doloremque laudantium, totam
            rem aperiam
          </p> -->
        </div>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="11">
        <card-block-img></card-block-img>
      </v-col>
      <v-col cols="11">
        <card-block-img></card-block-img>
      </v-col>
      <v-col cols="11">
        <card-block-img></card-block-img>
      </v-col>
    </v-row>

    <!-- Some releases app -->
    <some-releases></some-releases>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import FooterComponent from '@/layouts/components/FooterComponent.vue'
import HeaderComponent from '@/layouts/components/HeaderComponent.vue'
import CardBlockImg from "@/views/components/CardBlockImg.vue"
import SomeReleases from './components/SomeReleases.vue'

export default {
  components: {
    HeaderComponent,
    FooterComponent,
    CardBlockImg,
    SomeReleases
  },
  setup() {
    return {
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/styles/main-style.scss';
@import '~@/styles/utils-style.scss';
</style>
